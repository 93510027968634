// Dependency
import React, { useEffect, useState } from "react"
import ThankYouComponent from "../components/checkout/thank-you/thank-you"
import { useBrink } from "../context/BrinkContext"
import GA4 from "../services/tracking"

// Internationalization

const ThankYou = props => {
  // const [isTracked, setIsTracked] = useLocalStorage("order_tracked", false)

  const { getOrderConfirmation, getKlarnaOrder, cart, closeCart } = useBrink()

  const [confirmation, setConfirmation] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState(null)

  const triggerBee = order => {
    const email = order.billingAddress
      ? order.billingAddress.email
      : order.billing_address.email
    const revenue = order.orderAmountWithDiscount
      ? order.orderAmountWithDiscount / 100
      : order.order_amount / 100
    let tbContact = window.mtr_custom || {}
    tbContact.session = tbContact.session || {}

    tbContact.session.email = email

    window.mtr_custom = tbContact
    console.log(tbContact)
    window.mtr && window.mtr.goal("Completed Purchase", revenue)
  }

  useEffect(() => {
    if (confirmation) {
      window.addEventListener("afterTriggerbeeReady", triggerBee(confirmation))
      return () => {
        window.removeEventListener("afterTriggerbeeReady", triggerBee)
      }
    }
  }, [confirmation])

  useEffect(() => {
    const params = new URLSearchParams(props.location.search)
    const orderId = params.get("orderId")
    const signature = params.get("signature")
    const klarnaOrderId = params.get("klarnaOrderId")
    //const resultCode = params.get('resultCode');

    const getConfirmation = async (id, signature, klarna = false) => {
      if (klarna) {
        const klarnaOrder = await getKlarnaOrder(id)
        setPaymentMethod("klarna")
        setConfirmation(klarnaOrder)
        //TODO: Add isTracked condition to if
        if (klarnaOrder) {
          GA4.purchase(klarnaOrder, cart)
          //triggerBee("billingAddress")
          // setIsTracked(true)
        }
      } else {
        const order = await getOrderConfirmation(id, signature)
        setPaymentMethod("adyen")
        setConfirmation(order)
        //TODO: Add isTracked condition to if
        if (order) {
          GA4.purchase(order, cart)
          //triggerBee("billing_address")
          // setIsTracked(true)
        }
      }
    }

    if (klarnaOrderId) {
      getConfirmation(klarnaOrderId, null, true)
    } else {
      if (orderId) {
        getConfirmation(orderId, signature)
      } else {
        setConfirmation(props.location.state.order)
      }
    }
    closeCart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="body-bg">
      <div className="grid-container">
        <div className="grid__row">
          <div className="grid__col-xlarge-shift-4 grid__col-xlarge-4 grid__col-large-6 grid__col-medium-9 grid__col-small-2">
            {confirmation && (
              <ThankYouComponent
                order={confirmation}
                paymentMethod={paymentMethod}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThankYou

export const Head = () => <title>Tanrevel | Thank you!</title>
