//Dependencies
import React from "react"
import Price from "../../general/price/price"

//Styles
import "./cart-content-checkout.scss"

//Components
import CartItem from "../cart-item/cart-item"
import { useTranslation } from "react-i18next"
import RenderLink from "../../ui/link/link"

const CartContentCheckout = props => {
  const { t } = useTranslation()

  const cartItems = props.cart.cartItems.filter(
    item => item.type !== "shippingOption"
  )
  const vat =
    (props.cart.totalPriceWithDiscount * props.cart.store.taxPercentage) / 100

  return (
    <div className="cart-content-checkout">
      <div className="cart-content-checkout__content">
        {cartItems.length === 0 && (
          <div className="cart-content-checkout__empty-heading">
            {t("cart.empty")}
          </div>
        )}
        <CartItem cartItems={cartItems} controls={props.controls} />
      </div>

      {cartItems.length !== 0 && (
        <div className="cart-content-checkout--wrapper">
          <div className="cart-content-checkout__summary">
            <div className="cart-content-checkout__summary-line">
              {t("cart.price")}
              <span>
                <Price
                  prices={[
                    {
                      amount: props.cart.totalPrice,
                      currencyUnit: props.currencyUnit,
                    },
                  ]}
                />
              </span>
            </div>
            <div className="cart-content-checkout__summary-line">
              {t("cart.shipping")}
              <span>
                <Price
                  prices={[
                    {
                      amount: props.cart.totalShippingAmount,
                      currencyUnit: props.currencyUnit,
                    },
                  ]}
                />
              </span>
            </div>
            <div className="cart-content-checkout__summary-line">
              {t("cart.vat")}
              <span>
                <Price
                  prices={[
                    {
                      amount: vat,
                      currencyUnit: props.currencyUnit,
                    },
                  ]}
                />
              </span>
            </div>
            {props.cart.totalDiscountAmount !== 0 && (
              <div className="cart-content-checkout__summary-line cart-content-checkout__summary-line--discount">
                {t("cart.discount")}
                <span>
                  <Price
                    prices={[
                      {
                        amount: props.cart.totalDiscountAmount,
                        currencyUnit: props.currencyUnit,
                      },
                    ]}
                  />
                </span>
              </div>
            )}
            <div className="cart-content-checkout__sub-total">
              {t("cart.subtotal")}
              <span>
                <Price
                  prices={[
                    {
                      amount: props.cart.totalPriceWithDiscount,
                      currencyUnit: props.currencyUnit,
                    },
                  ]}
                />
              </span>
            </div>
          </div>
        </div>
      )}
      <div className="cart-content-checkout__button">
        <RenderLink
          asset="arrow-right-white"
          linkText={t("cart.continueToHome")}
          linkUrl="/"
          buttonLook="black"
        />
      </div>
    </div>
  )
}

export default CartContentCheckout
