import React, { useEffect, useRef } from "react"


const KlarnaConfirmation = ({ snippet }) => {
    const kcoRef = useRef(null)
    const useMountEffect = (fun) =>
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(fun, [])

    useMountEffect(() => {
        const scriptsTags = kcoRef.current.getElementsByTagName("script")
        // This is necessary otherwise the scripts tags are not going to be evaluated
        for (let i = 0; i < scriptsTags.length; i++) {
            const parentNode = scriptsTags[i].parentNode
            const newScriptTag = document.createElement("script")
            newScriptTag.type = "text/javascript"
            newScriptTag.text = scriptsTags[i].text
            parentNode.removeChild(scriptsTags[i])
            parentNode.appendChild(newScriptTag)
        }
    })

    return (
        <div ref={kcoRef} dangerouslySetInnerHTML={{ __html: snippet }} />
    )
}

export default KlarnaConfirmation