//Dependencies
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import CartContentCheckout from "../../cart/cart-content-checkout/cart-content-checkout"

//Styles
import "./thank-you.scss"
import KlarnaConfirmation from "../payment-provider/klarna-confirmation"

const ThankYou = ({ order, paymentMethod }) => {
  const { t } = useTranslation()

  const toAddress = (field, type) => {
    if (paymentMethod === "klarna") {
      const klarnaType =
        type === "billingAddress" ? "billing_address" : "shipping_address"
      const address = {
        email: order[klarnaType].email,
        givenName: order[klarnaType].given_name,
        familyName: order[klarnaType].family_name,
        phone: order[klarnaType].phone,
        streetAddress: order[klarnaType].street_address,
        streetAddress2: order[klarnaType].street_address2,
        city: order[klarnaType].city,
        postalCode: order[klarnaType].postal_code,
        region: "",
        country: order[klarnaType].country,
      }
      return address[field]
    } else {
      return order[type][field]
    }
  }

  useEffect(() => {
    const initialScript = document.createElement('script');
    initialScript.innerHTML = `
    var APPRL={};
    APPRL.Tracking={};
    APPRL.Tracking.Sale={};

    /* Conversion data */
    APPRL.Tracking.Sale.order_id='${order.merchant_reference2
        ? order.merchant_reference1
        : order.reference}'; // Order id from store
    APPRL.Tracking.Sale.order_value='${order.merchant_reference2
        ? order.order_amount / 100
        : order.orderAmountWithDiscount / 100}'; // The order value
    APPRL.Tracking.Sale.currency='${order.merchant_reference2
        ? order.purchase_currency
        : order.currencyUnit}'; // Currency, has to be three letters i.e. SEK, GBP, EUR
    `;
    document.body.appendChild(initialScript);

    const script = document.createElement('script');
    script.src = "https://s.apprl.com/js/apprl.js";
    script.async = true;
    document.body.appendChild(script);

  }, [])

  const shipment =
    paymentMethod === "klarna"
      ? order.order_lines.find(line => line.type === "shipping_fee")
      : order.orderLines.find(line => line.type === "shipment")

  return (
    <>

      <div className="thank-you">
        <div className="thank-you__main-heading-wrap">
          <div className="thank-you__main-heading">
            {/* <Image
              largeScreenImage={
                componentProps &&
                componentProps.content &&
                componentProps.content.image &&
                componentProps.content.image.filename
              }
              smallScreenImage={
                componentProps &&
                componentProps.content &&
                componentProps.content.image &&
                componentProps.content.image.filename
              }
              largeScreenHeight={160}
              largeScreenWidth={160}
              smallScreenHeight={"auto"}
              smallScreenWidth={150}
              alt={"image"}
              layout={"constrained"}
            /> */}
            {t("thankyou.thankYou")}
          </div>
          <p className="thank-you__main-description">
            {t("thankyou.orderReady")}
          </p>
        </div>
        {order.html_snippet && (
          <KlarnaConfirmation snippet={order.html_snippet} />
        )}
        <div className="thank-you__content">
          <div className="thank-you__inner">
            <div className="thank-you__heading-wrap">
              <h1 className="thank-you__heading">{t("thankyou.orderNumber")}</h1>
              <p>
                {`#${order.reference ? order.reference : order.merchant_reference1
                  }`}{" "}
              </p>
            </div>
            <div className="thank-you__billing">
              <h1 className="thank-you__billing__heading">
                {t("thankyou.billingAddress")}
              </h1>
              <p className="thank-you__billing__description">
                {`${toAddress("givenName", "billingAddress")} ${toAddress(
                  "familyName",
                  "billingAddress"
                )}`}
                <br />
                {toAddress("email", "billingAddress")}
                <br />
                {toAddress("streetAddress", "billingAddress")}
                <br />
                {`${toAddress("postalCode", "billingAddress")}, ${toAddress(
                  "city",
                  "billingAddress"
                )}, ${toAddress("country", "billingAddress")}`}
              </p>
            </div>
            <div className="thank-you__shipping">
              <h1 className="thank-you__shipping__heading">
                {t("thankyou.ShippingAddress")}
              </h1>
              <p className="thank-you__shipping__description">
                {`${toAddress("givenName", "shippingAddress")} ${toAddress(
                  "familyName",
                  "shippingAddress"
                )}`}
                <br />
                {toAddress("email", "billingAddress")}
                <br />
                {toAddress("streetAddress", "shippingAddress")}
                <br />
                {`${toAddress("postalCode", "shippingAddress")}, ${toAddress(
                  "city",
                  "shippingAddress"
                )}, ${toAddress("country", "shippingAddress")}`}
              </p>
              <div className="thank-you__shipping-type">
                <span className="thank-you__shipping-type-icon"></span>
                {shipment.name}
              </div>
            </div>
            {order.cart && (
              <CartContentCheckout
                cart={order.cart}
                currencyUnit={order.currencyUnit}
                controls={false}
              />
            )}
          </div>
        </div>
      </div>
    </>

  )
}

export default ThankYou
